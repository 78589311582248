import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// Thunk for fetching materials content
export const getMaterialsContent = createAsyncThunk('/materials/content', async () => {
    const response = await axios.get('api/materials', {})
    return response.data;
})

export const materialsSlice = createSlice({
    name: 'materials',
    initialState: {
        isLoading: false,
        materials: []
    },
    reducers: {
        addNewMaterial: (state, action) => {
            const { newMaterialObj } = action.payload
            state.materials = [...state.materials, newMaterialObj]
        },

        deleteMaterial: (state, action) => {
            const { index } = action.payload
            state.materials.splice(index, 1)
        }
    },

    extraReducers: {
        [getMaterialsContent.pending]: state => {
            state.isLoading = true
        },
        [getMaterialsContent.fulfilled]: (state, action) => {
            state.materials = action.payload.data
            state.isLoading = false
        },
        [getMaterialsContent.rejected]: state => {
            state.isLoading = false
        },
    }
})

export const { addNewMaterial, deleteMaterial } = materialsSlice.actions

export default materialsSlice.reducer
