import axios from "axios"

const checkAuth = () => {
  // Getting token value stored in localStorage
  const TOKEN = localStorage.getItem("token")

  // Define public routes that don't require authentication
  const PUBLIC_ROUTES = ["/login", "/forgot-password", "/register", "/documentation", "/TentangKami", "/kontak", "/", "/ListArmada"]

  // Check if the current page is public
  const isPublicPage = PUBLIC_ROUTES.includes(window.location.pathname)
  console.log('Current Path from authjs:', window.location.pathname);


  if (!TOKEN && !isPublicPage) {
    // If no token and the page is private, redirect to login
    window.location.href = '/login'
    return;
  } else {
    // Set token as Authorization header for all requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`

    // Show loading indicator during requests
    axios.interceptors.request.use(function (config) {
      document.body.classList.add('loading-indicator');
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    // Hide loading indicator after requests
    axios.interceptors.response.use(function (response) {
      document.body.classList.remove('loading-indicator');
      return response;
    }, function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    });

    return TOKEN
  }
}

export default checkAuth
